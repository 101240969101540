import React from "react";
import './app.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Header from "./components/Header";
import Main from "./components/Main";


AOS.init();
AOS.init({

  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  offset: 120, // offset (in px) from the original trigger point
  delay: 0, // values from 0 to 3000, with step 50ms
  duration: 700, // values from 0 to 3000, with step 50ms
  easing: 'ease', // default easing for AOS animations
  once: false, // whether animation should happen only once - while scrolling down
  mirror: false, // whether elements should animate out while scrolling past them
  anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation

});
function App() {
  return (
    <>
      <Header/>
      <Main/>
    </>
  );
}

export default App;
